<template>
  <div class="businessCircle">
    <div class="businessCircle-t" v-if="checkStatus == 5">
      <div class="img">
        <img src="./img/empty.png" alt="" />
      </div>
      <div class="info">您还未申请商圈，可点击下方申请按钮申请哦</div>
      <div class="btn" @click="toBusinessCircle">申请商圈</div>
    </div>
    <div class="businessCircle-c2" v-if="checkStatus == 2">
      <div class="img">
        <img src="./img/unpass.png" alt="" />
      </div>
      <div class="info">审核未通过</div>
      <div class="btn" @click="toBusinessCircle('isEdit')">重新提交</div>
    </div>
    <div class="businessCircle-c2" v-if="checkStatus == 6">
      <div class="img">
        <img src="./img/unpass.png" alt="" />
      </div>
      <div class="info">申诉失败</div>
      <div class="btn">重新提交</div>
    </div>
    <div class="businessCircle-c3" v-if="checkStatus == 0">
      <div class="img">
        <img src="./img/checking.png" alt="" />
      </div>
      <div class="info">商户审核中</div>
      <div class="btn">审核通过后，即可查看商户主页面</div>
    </div>
    <div class="businessCircle-c3" v-if="checkStatus == 4">
      <div class="img">
        <img src="./img/checking.png" alt="" />
      </div>
      <div class="info">申诉中</div>
      <div class="btn">等待管理员审核</div>
    </div>
    <div class="businessCircle-b" v-if="checkStatus == 1 || checkStatus == 3">
      <div class="myBusiness">
        <div class="myBusiness-t">
          <div class="img">
            <img
              :src="
                myData.imageUrl
                  ? myData.imageUrl
                  : require('@/assets/img/default_avatar.png')
              "
              alt=""
            />
          </div>
          <div class="txt">
            <div class="txt-t" @click="toBusinessCircle('isEdit')">
              <div class="txt-tl">{{ myData.title }}</div>
              <div class="txt-tr">编辑</div>
            </div>
            <div class="txt-b">已建立商圈{{ myData.days }}天</div>
          </div>
        </div>
        <div class="myBusiness-b">
          <div class="main">
            <div class="item te" @click="toOrder">
              <div class="item-t">交易笔数</div>
              <div class="item-b">{{ myData.total }}</div>
            </div>
            <div class="item te" @click="toPoint">
              <div class="item-t">待核销积分</div>
              <div class="item-b">{{ myData.noConsumedPonints }}</div>
            </div>
            <div class="item">
              <div class="item-t">已核销积分</div>
              <div class="item-b">{{ myData.consumedPonints }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="myCast">
        <div class="title">我的商品</div>
        <div class="main">
          <div class="item">
            <div class="item-t">{{ myData.putawaying }}</div>
            <div class="item-b">销售中</div>
          </div>
          <div class="item">
            <div class="item-t">{{ myData.noPutaway }}</div>
            <div class="item-b">待上架</div>
          </div>
          <div class="item">
            <div class="item-t">{{ myData.putawayed }}</div>
            <div class="item-b">已上架</div>
          </div>
        </div>
      </div>
      <div class="myAccount">
        <div class="title">我的账号</div>
        <div class="body">
          <div class="body-c">
            <div class="item">
              账号地址：
              {{ myData.accountAddress }}
            </div>
            <div class="item">
              账户名：
              {{ myData.loginName }}
            </div>
            <div class="item">
              账号密码：
              {{ myData.loginPwd }}
            </div>
          </div>
        </div>
      </div>
      <div class="freeze" v-if="checkStatus == 3 && isFreeze">
        <div class="freeze-c">
          <div class="freeze-ct">您的商户已冻结</div>
          <div class="freeze-cc">您商户涉嫌发布商品违规操作，已将商户冻结</div>
          <div class="freeze-cb" @click="toAppeal">申诉</div>
          <div class="close" @click="close">
            <img src="./img/close.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getBusinessCircleStatusUrl, getMyBusinessUrl } from "./api.js";
import { toRegister } from "@/utils/common";
var moment = require("moment");
export default {
  name: "businessCircle",
  data() {
    return {
      checkStatus: null,
      isFreeze: true,
      myData: {
        consumedPonints: 0,
        noConsumedPonints: 0,
        total: 0,
        days: 0,
        title: "",
        putawayed: 0,
        noPutaway: 0,
        putawaying: 0,
      },
    };
  },
  async created() {
    if (await toRegister(this.$route.path, this.$route.query, "商圈申请")) {
      if (this.$route.query.latitude) {
        localStorage.setItem("latitude", this.$route.query.latitude);
      }
      if (this.$route.query.longitude) {
        localStorage.setItem("longitude", this.$route.query.longitude);
      }
      this.getStatus();
    }
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
    communityId() {
      return this.$store.state.communityId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    openId() {
      return this.$store.state.openId;
    },
  },
  destroyed() {},
  methods: {
    toPoint() {
      this.$router.push({
        name: "verificationList",
      });
    },
    toOrder() {
      this.$router.push({
        name: "businessGood",
        query: {
          shopId: this.myData.id,
        },
      });
    },
    toBusinessCircle(data) {
      if (data == "isEdit") {
        this.$router.push({
          name: "businessCircleApply",
          query: {
            isEdit: 1,
          },
        });
      } else {
        this.$router.push({
          name: "businessCircleApply",
        });
      }
    },
    toAppeal() {
      this.$toast("暂未开放");
      return;
      // this.$router.push({
      //   name: "appeal",
      // });
    },
    close() {
      this.isFreeze = false;
    },
    async getMyBusiness() {
      let params = {
        tenantId: this.tenantId,
        houseId: this.roomId || this.communityId || undefined,
        userId: this.userId,
      };

      let res = await this.$axios.get(`${getMyBusinessUrl}`, {
        params,
      });
      if (res.code == 200) {
        this.myData = res.data;
      }
    },
    async getStatus() {
      let params = {
        userId: this.userId,
        tenantId: this.tenantId,
        houseId: this.roomId || this.communityId || undefined,
      };

      let res = await this.$axios.get(`${getBusinessCircleStatusUrl}`, {
        params,
      });
      if (res.code == 200) {
        this.checkStatus = res.data;
        if (this.checkStatus == 1) {
          this.getMyBusiness();
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
.businessCircle {
  background: #fafafa;
  min-height: 100vh;

  .businessCircle-t {
    padding-top: 192px;
    .img {
      width: 398px;
      height: 240px;
      margin: 0 auto;
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .info {
      text-align: center;
      font-size: 28px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.25);
      line-height: 40px;
      margin: 70px auto 122px;
    }
    .btn {
      width: 550px;
      height: 66px;
      background: linear-gradient(180deg, #ffc055 0%, #fe9514 100%);
      border-radius: 10px;
      font-size: 32px;
      text-align: center;
      font-weight: 600;
      color: #ffffff;
      line-height: 66px;
      margin: 0 auto;
    }
  }
  .businessCircle-c2 {
    padding-top: 200px;
    .img {
      width: 328px;
      height: 198px;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .info {
      font-size: 42px;
      font-weight: 600;
      color: #e02020;
      line-height: 58px;
      margin: 56px auto 120px;
      text-align: center;
    }
    .btn {
      width: 550px;
      height: 66px;
      background: linear-gradient(180deg, #ffc055 0%, #fe9514 100%);
      border-radius: 10px;
      text-align: center;
      font-size: 32px;
      font-weight: 600;
      color: #ffffff;
      line-height: 66px;
      margin: 0 auto;
    }
  }
  .businessCircle-c3 {
    padding-top: 200px;
    .img {
      width: 328px;
      height: 198px;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .info {
      font-size: 42px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #0091ff;
      line-height: 58px;
      margin: 56px auto 16px;
      text-align: center;
    }
    .btn {
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.25);
      line-height: 40px;
      text-align: center;
      margin: 0 auto;
    }
  }
  .businessCircle-b {
    .title {
      padding: 58px 0 24px 36px;
      font-size: 34px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 48px;
    }
    .main {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 36px;
      .item {
        width: 214px;
        height: 142px;
        text-align: center;
        box-sizing: border-box;
        padding-top: 26px;
        box-shadow: 0px 4px 12px 0px rgba(236, 236, 236, 0.5);
        border-radius: 4px;
        background: #ffffff;
        .item-t {
          font-size: 26px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.25);
          line-height: 36px;
        }
        .item-b {
          margin-top: 6px;
          font-size: 38px;
          font-weight: 600;
          color: #fe9616;
          line-height: 52px;
        }
      }
    }
    .myBusiness-t {
      padding: 42px 46px 48px;
      background: #fff;
      display: flex;
      align-items: center;
      .img {
        width: 112px;
        height: 112px;
        margin-right: 20px;
        border-radius: 50%;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .txt {
        .txt-t {
          display: flex;
          align-items: center;
          .txt-tl {
            font-size: 34px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);
            line-height: 48px;
            margin-right: 16px;
          }
          .txt-tr {
            width: 64px;
            height: 34px;
            background: #fff4e7;
            border-radius: 6px;
            border: 2px solid #fe9616;
            font-size: 22px;
            text-align: center;
            font-weight: 400;
            color: #fe9616;
            line-height: 34px;
          }
        }
        .txt-b {
          font-size: 22px;
          font-weight: 400;
          margin-top: 6px;
          color: rgba(0, 0, 0, 0.25);
          line-height: 32px;
        }
      }
    }
    .myBusiness-b {
      padding: 38px 0 0 0;
      .te {
        .item-b {
          position: relative;
          &::after {
            content: "";
            position: absolute;
            width: 40%;
            bottom: -4px;
            left: 0;
            right: 0;
            margin: auto;
            height: 2px;
            background: #fe9616;
          }
        }
      }
    }
    .myCast {
      .item {
        padding: 26px 0 0 0;
        .item-t {
          font-size: 38px;
          font-weight: 600;
          color: #fe9616;
          line-height: 52px;
        }
        .item-b {
          font-size: 26px;
          margin-top: 4px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.25);
          line-height: 36px;
        }
      }
    }
    .myAccount {
      .body {
        padding: 0 36px;
        .body-c {
          background: #fff;
          padding: 30px 34px 128px;
          box-shadow: 0px 4px 8px 0px rgba(244, 242, 242, 0.5);
          font-size: 28px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
          line-height: 50px;
        }
      }
    }
    .freeze {
      height: 100vh;
      width: 100%;
      z-index: 99;
      top: 0;
      left: 0;
      position: fixed;
      background: rgba(0, 0, 0, 0.25);
      .freeze-c {
        width: 616px;
        height: 666px;
        background: #ffffff;
        border-radius: 16px;
        position: absolute;
        z-index: 100;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        padding: 86px 48px 0;
        box-sizing: border-box;
        text-align: center;
        .freeze-ct {
          font-size: 36px;
          font-weight: 600;
          color: #333333;
          line-height: 50px;
        }
        .freeze-cc {
          font-size: 32px;
          font-weight: 400;
          color: #333333;
          line-height: 44px;
          margin: 80px 0 174px;
        }
        .freeze-cb {
          width: 466px;
          height: 66px;
          background: linear-gradient(180deg, #ffc055 0%, #fe9615 100%);
          border-radius: 10px;
          font-size: 30px;
          text-align: center;
          font-weight: 600;
          color: #ffffff;
          line-height: 66px;
          margin: 0 auto;
        }
        .close {
          position: absolute;
          width: 76px;
          height: 76px;
          left: 0;
          right: 0;
          bottom: -138px;
          margin: auto;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
.businessCircle {
}
</style>
